

























import { Component, Prop, Vue } from 'vue-property-decorator';
import ContextMenuItem from '@/commoncomponents/contextMenuComponents/ContextMenuItem.vue';
import { PropType } from 'vue';

const variants = ['neutral', 'primary'] as const;
type ContextMenuVariant = typeof variants[number];

const elevations = ['100', '200', '300', '400'] as const;
type ContextMenuElevation = typeof elevations[number];

const modes = ['hover', 'click'] as const;
type ContextMenuMode = typeof modes[number];

const positions = [
  'top-left',
  'top-center',
  'top-right',
  'bottom-left',
  'bottom-center',
  'bottom-right',
  'left-top',
  'left-center',
  'left-bottom',
  'right-top',
  'right-center',
  'right-bottom',
] as const;
type ContextMenuPosition = typeof positions[number];

@Component({
  components: {
    ContextMenuItem
  }
})
export default class ContextMenu extends Vue {
  @Prop({ type: String as PropType<ContextMenuVariant>, default: 'primary' })
  variant!: ContextMenuVariant;

  @Prop({ type: String as PropType<ContextMenuElevation>, default: '300' })
  elevation!: ContextMenuElevation;

  @Prop({ type: String as PropType<ContextMenuMode>, default: 'click' })
  mode!: ContextMenuMode;

  @Prop({ type: String as PropType<ContextMenuPosition | ''>, default: '' })
  position!: ContextMenuPosition;

  get options(): string {
    const values = [`mode: ${this.mode}`];

    if (this.position) {
      values.push(`pos: ${this.position}`);
    }

    return values.join(';');
  }
}

